import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import { Box, Container, Button } from "@material-ui/core";
import { TextOverlayType } from "./styledComponents";
import { Link } from "gatsby";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LinkButton from "./link-button";

interface HeroProps {
    image: import("gatsby-source-filesystem").FileSystemNode;
    title: string;
    tagline?: string;
    heroHeight: number;
    overlayColor: string;
    ctaText?: string;
    ctaUrl?: string;
    cta2Text?: string;
    cta2Url?: string;

}

export const hexToRgb = (hex: string) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export const getBackgroundImageStack = (image: import("gatsby-background-image").IFluidObject, color: string) => {
    var rgb = hexToRgb(color);
    return [
        image,
        `linear-gradient(rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.53), rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.27))`
    ].reverse();
};

export const HeroImage = styled(BackgroundImage)`
        margin-top: -64px;
    `;

export const HeroTagLine = styled(TextOverlayType)`
    width:100%;
    display:block;
`;

const Hero: React.FC<HeroProps> = (props) => {
    return (
        <HeroImage fadeIn={false} loading="eager" fluid={getBackgroundImageStack((props.image.childImageSharp as any).fluid, props.overlayColor)}>
            <Box display="flex" flexWrap="wrap" alignItems="center" height={`${props.heroHeight}vh`} minHeight={450}>
                <Container>
                    <TextOverlayType shadowColor={props.overlayColor} variant="h1" align="center" gutterBottom={true}>{props.title}</TextOverlayType>
                    {props.tagline &&
                        <HeroTagLine shadowColor={props.overlayColor} variant="h3" component="h2" align="center">
                            {props.tagline}
                        </HeroTagLine>
                    }

                    {props.ctaText &&
                        <Box display="flex" justifyContent="center" pt={4}>
                            <LinkButton variant="contained" color="secondary" size="large"
                                url={props.ctaUrl} >
                                {props.ctaText}
                            </LinkButton>
                            {props.cta2Text &&
                            <Box pl={2}>
                            <LinkButton variant="contained" color="primary" size="large"
                                url={props.cta2Url} >
                                {props.cta2Text}
                            </LinkButton>
                            </Box>
                            }
                        </Box>
                    }
                </Container>
            </Box>
        </HeroImage>
    );
}

export default Hero;